// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-toggle-container .grid-toggler {
  display: inline-block;
  padding: 0.1rem 0.3rem;
  border-radius: 4px;
}
.grid-toggle-container .grid-toggler:first-child {
  border-right-width: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.grid-toggle-container .grid-toggler:last-child {
  border-left-width: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.grid-toggle-container .grid-toggler.active {
  background-color: var(--mui-palette-primary-main, #000);
  color: var(--mui-palette-background-default, #fff);
  border: 1px solid var(--mui-palette-primary-main, #000);
}
.grid-toggle-container .grid-toggler:not(.active) {
  color: var(--mui-palette-primary-main);
  border: 1px solid var(--mui-palette-primary-main);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shop/menu/ShopFilters/ViewToggle/styles.scss"],"names":[],"mappings":"AAIE;EACE,qBAAA;EAEA,sBAAA;EAEA,kBAAA;AALJ;AAOI;EACE,gCAAA;EACA,qCAAA;EACA,wCAAA;AALN;AAQI;EACE,+BAAA;EACA,oCAAA;EACA,uCAAA;AANN;AASI;EACE,uDAAA;EACA,kDAAA;EACA,uDAAA;AAPN;AASI;EACE,sCAAA;EACA,iDAAA;EACA,eAAA;AAPN","sourcesContent":["\n\n\n.grid-toggle-container {\n  .grid-toggler {\n    display: inline-block;\n\n    padding: .1rem .3rem;\n\n    border-radius: 4px;\n\n    &:first-child {\n      border-right-width: 0!important;\n      border-top-right-radius: 0!important;\n      border-bottom-right-radius: 0!important;\n    }\n\n    &:last-child {\n      border-left-width: 0!important;\n      border-top-left-radius: 0!important;\n      border-bottom-left-radius: 0!important;\n    }\n\n    &.active {\n      background-color: var(--mui-palette-primary-main, #000);\n      color: var(--mui-palette-background-default, #fff);\n      border: 1px solid var(--mui-palette-primary-main, #000);\n    }\n    &:not(.active) {\n      color: var(--mui-palette-primary-main);\n      border: 1px solid var(--mui-palette-primary-main);\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
