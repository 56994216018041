// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-chat-window {
  color: var(--mui-palette-text-primary);
  overflow: hidden !important;
}
@media screen and (max-width: 599px) {
  .ai-chat-window {
    width: 95vw !important;
  }
}
@media screen and (min-width: 600px) {
  .ai-chat-window {
    width: 500px;
  }
}
.ai-chat-window .chat-wrapper .header {
  padding: 10px;
}
.ai-chat-window .chat-wrapper .chat {
  border: none !important;
}

.ai-chat-toggler {
  border-radius: 100%;
}
.ai-chat-toggler:not(.no-fixed) {
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 1000;
}
.ai-chat-toggler button {
  border-radius: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/AIChat/AIChatWindow/styles.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EAEA,2BAAA;AAAJ;AAEI;EALJ;IAMQ,sBAAA;EACN;AACF;AACI;EATJ;IAUQ,YAAA;EAEN;AACF;AAEQ;EACI,aAAA;AAAZ;AAGQ;EACI,uBAAA;AADZ;;AAOA;EAQI,mBAAA;AAXJ;AAII;EACI,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AAFR;AAOI;EACI,mBAAA;AALR","sourcesContent":[".ai-chat-window {\n    color: var(--mui-palette-text-primary);\n\n    overflow: hidden!important;\n\n    @media screen and (max-width: 599px) {\n        width: 95vw!important;\n    }\n\n    @media screen and (min-width: 600px) {\n        width: 500px;\n    }\n\n\n    .chat-wrapper {\n        .header {\n            padding: 10px;\n\n        }\n        .chat {\n            border: none!important;\n        }\n    }\n}\n\n\n.ai-chat-toggler {\n    &:not(.no-fixed) {\n        position: fixed;\n        bottom: 15px;\n        right: 20px;\n        z-index: 1000;\n    }\n\n    border-radius: 100%;\n\n    button {\n        border-radius: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
