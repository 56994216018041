// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container.show {
  opacity: 1;
  display: block;
  transition: opacity, display 0.5s;
}
.loader-container:not(.show) {
  opacity: 0;
  transition: opacity, display 0.5s;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/loader/loader.scss"],"names":[],"mappings":"AACC;EACC,UAAA;EACA,cAAA;EACA,iCAAA;AAAF;AAGC;EACC,UAAA;EACA,iCAAA;EACA,aAAA;AADF","sourcesContent":[".loader-container {\n\t&.show {\n\t\topacity: 1;\n\t\tdisplay: block;\n\t\ttransition: opacity, display .5s;\n\t}\n\n\t&:not(.show) {\n\t\topacity: 0;\n\t\ttransition: opacity, display .5s;\n\t\tdisplay: none;\n\t}\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
