import React from "react";
import {MDBDatepicker} from "mdb-react-ui-kit";

import {getMonthNames, getDayNames} from "../index";
import {DatepickerProps} from "./types";


export function TgDatePicker({lang, className = '', ...props}: DatepickerProps){
    return (
        <MDBDatepicker
            monthsFull={getMonthNames(lang)}
            monthsShort={getMonthNames(lang, 'short')}
            weekdaysFull={getDayNames(lang)}
            weekdaysShort={getDayNames(lang, 'short')}
            weekdaysNarrow={getDayNames(lang, 'narrow')}
            className={`tg-input ${className ? className : ''}`}
            {...props}
        />
    )
}
