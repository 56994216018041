import {MDBTimepicker} from "mdb-react-ui-kit";
import React from "react";
import {TimepickerProps} from "./types";

export function TgTimePicker({className = '', ...props}: TimepickerProps){
    return (
        <MDBTimepicker
            className={`tg-input tg-group ${className ? className : ''}`}
            {...props}
        />
    )
}
