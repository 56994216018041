// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check .check-item .check-item-image-wrapper {
  width: 20%;
  margin-right: 0.5rem;
}
.check .check-item .check-item-image-wrapper .check-item-image {
  border-radius: 4px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/features/Check/check.scss"],"names":[],"mappings":"AAEI;EACE,UAAA;EACA,oBAAA;AADN;AAGM;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AADR","sourcesContent":[".check {\n  .check-item {\n    .check-item-image-wrapper {\n      width: 20%;\n      margin-right: .5rem;\n\n      .check-item-image {\n        border-radius: 4px;\n        max-width: 100%;\n        max-height: 100%;\n        object-fit: contain;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
