// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes grow {
  0% {
    transform: translateY(-160px);
  }
  30% {
    transform: translateY(-160px);
  }
  100% {
    transform: none;
  }
}
@keyframes opacityReverse {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cartControlsShadow {
  0% {
    box-shadow: var(--mui-shadows-4);
  }
  90% {
    box-shadow: var(--mui-shadows-4);
  }
  100% {
    box-shadow: none;
  }
}
#modal-or-swipeable-overflow-wrapper {
  scroll-timeline-name: --containerTimeline;
  view-timeline-name: --containerViewTimeline;
}`, "",{"version":3,"sources":["webpack://./src/shop/menu/product/product.scss"],"names":[],"mappings":"AAAA;EACI;IAAI,6BAAA;EAEN;EADE;IAAK,6BAAA;EAIP;EAHE;IAAM,eAAA;EAMR;AACF;AAJA;EACI;IAAI,UAAA;EAON;EANE;IAAK,UAAA;EASP;EARE;IAAM,UAAA;EAWR;AACF;AATA;EACI;IAAI,UAAA;EAYN;EAXE;IAAK,UAAA;EAcP;EAbE;IAAM,UAAA;EAgBR;AACF;AAdA;EACI;IAAI,gCAAA;EAiBN;EAhBE;IAAK,gCAAA;EAmBP;EAlBE;IAAM,gBAAA;EAqBR;AACF;AAnBA;EACI,yCAAA;EACA,2CAAA;AAqBJ","sourcesContent":["@keyframes grow {\n    0% {transform: translateY(-160px);}\n    30% {transform: translateY(-160px);}\n    100% {transform: none;}\n}\n\n@keyframes opacityReverse {\n    0% {opacity: 1;}\n    85% {opacity: 1;}\n    100% {opacity: 0;}\n}\n\n@keyframes opacity {\n    0% {opacity: 0;}\n    50% {opacity: 0;}\n    100% {opacity: 1;}\n}\n\n@keyframes cartControlsShadow {\n    0% {box-shadow: var(--mui-shadows-4);}\n    90% {box-shadow: var(--mui-shadows-4);}\n    100% {box-shadow: none;}\n}\n\n#modal-or-swipeable-overflow-wrapper{\n    scroll-timeline-name: --containerTimeline;\n    view-timeline-name: --containerViewTimeline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
