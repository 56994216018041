// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.external-login-logo {
  width: 18px;
  height: 18px;
}

.incust-logo {
  width: 24px;
  height: 24px;
  margin-left: -1.5px;
}

.google-logo {
  background: url("https://d2k1ftgv7pobq7.cloudfront.net/meta/c/p/res/images/8215f6659adc202403198fef903a447e/sign-in-with-google.svg");
}

.external-login-container {
  padding: 0 12px;
}

.auth-link-header a {
  font-size: 1.3rem;
}`, "",{"version":3,"sources":["webpack://./src/auth/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,qIAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAGE;EACE,iBAAA;AAAJ","sourcesContent":[".external-login-logo {\n  width: 18px;\n  height: 18px\n}\n\n.incust-logo {\n  width: 24px;\n  height: 24px;\n  margin-left: -1.5px;\n}\n\n.google-logo {\n  background: url(\"https://d2k1ftgv7pobq7.cloudfront.net/meta/c/p/res/images/8215f6659adc202403198fef903a447e/sign-in-with-google.svg\");\n}\n\n.external-login-container {\n  padding: 0 12px;\n}\n\n.auth-link-header{\n  a{\n    font-size: 1.3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
